<template>
    <div class="CompileRun">
        <!-- 表单弹窗 -->
        <div class="from">
            <el-dialog title="节假日编辑" :visible.sync="yunyingtan" :closeOnClickModal='false' width="700px" @close="closeDialog()">
                <el-form :model="form">
                    <!-- <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            查询用户:
                        </div>
                        <el-select v-model="phone" ref="searchSelect" filterable placeholder="请输入手机号" @input.native="filterData">
                            <el-option v-for="item in restaurants" :key="item.id" :label="item.account" :value="item.id">
                                <span style="float: left"><img :src="item.server_wechat.wechat_head" alt="" style="width:30px;height:30px;"></span>
                                <span style="float: left;margin-left:5px">{{  Base64.decode(item.server_wechat.wechat_name)  }}</span>
                                <span style="float: right; color: #8492a6; font-size: 13px">{{ item.account  }}</span>
                            </el-option>
                        </el-select>
                    </el-form-item> -->

                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            假期名称:
                        </div>
                        <el-input v-model="form.holiday" autocomplete="off" placeholder="请输入假期名称"></el-input>
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            类型:
                        </div>
                        <el-select v-model="form.day_off" placeholder="请选择类型">
                            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai" style="width: 150px; ">
                            <span class="xing">* </span>
                            假期留言提醒:
                        </div>
                        <el-input type="textarea" :rows="4" placeholder="请输入假期留言" v-model="form.text" style="margin-left:20px">
                        </el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="closeDialog" class="success button">取 消</el-button>
                    <el-button type="primary" @click="tijiao" class="tijiao primary button">提 交</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            token: JSON.parse(localStorage.getItem("TZ-USER")).admin_token,
            yunyingtan: false, //操控弹窗展示
            formLabelWidth: "140px",
            dialogImageUrl: "",
            dialogVisible: false,
            page: 1,
            inputD: "",
            checkList: [], //配音师标签
            localismList: [], //配音师方言标签
            fileList: [],
            restaurants: [],
            phone: "",
            // SourceList: "",
            options: [{
                value: 1,
                label: '工作日'
            }, {
                value: 2,
                label: '节假日'
            },],
            form: {
                day_off: "",
                holiday: "",
                text: ""
            }
        };
    },
    mounted() {
    },
    components: {
    },
    methods: {
        onyunyingtan(data) {
            // this.form.day_num=data.day_num;
            // this.form.id=data.id;
            // this.form.user_name=data.user_name;
            // this.form.user_id=data.server_user.id;
            // this.phone=data.server_user.account;
            this.form = data;
            this.yunyingtan = true;
        },
        closeDialog() {
            this.form = {
                day_off: '',
                holiday: "",
                text: ""
            };
            this.yunyingtan = false;
        },
        // 提交编辑
        tijiao() {
            var param = {
                day_off: '',
                holiday: "",
                text: "",
                id:""
            };
            param.day_off=this.form.day_off;
            param.holiday=this.form.holiday;
            param.text=this.form.text;
            param.id=this.form.id;
            if (param.day_off == "") {
                this.$message({ message: "请输入类型", type: "warning" });
                return;
            }
            if (param.holiday == "") {
                this.$message({ message: "请输入假期名称", type: "warning" });
                return;
            }
            if (param.text == "") {
                this.$message({ message: "请输入假期留言", type: "warning" });
                return;
            }
            this.$service.post(this.$api.setHolidayField, param, (res) => {
                if (res.code == "200") {
                    this.$message({
                        message: "编辑成功",
                        type: "success",
                    });
                    this.closeDialog()
                    this.$parent.getSysMessageTemp();
                }
            });
        },

    },
};
</script>

<style lang="scss" scoped>
.CompileRun {
    .chuangjian {
        // height: 40px;
        line-height: 40px;
    }
    .xing {
        color: red;
        margin-right: 6px;
    }
    .kuai {
        width: 140px;
        display: inline-block;
    }
    .tijiao {
        margin-left: 40px;
    }
    // .button {
    //     width: 90px;
    //     color: #ffffff;
    // }
    .img {
        width: 148px;
        height: 148px;
        margin-right: 20px;
    }
    //
}
</style>
<style lang="scss">
.CompileRun {
    .upload-demo {
        display: inline-block;
    }
    .el-upload-dragger {
        height: 150px;
    }
    .el-upload__tip {
        width: 250px;
    }
    .el-dialog__header {
        background: #0075f7;
    }
    .el-dialog__title {
        color: #ffffff;
    }
    .el-form-item__content {
        display: flex;
    }
    .el-radio {
        line-height: 40px;
    }
}
</style>